<template>
    <div class="bi-main">
        <div class="data-table">
            <DataTable />
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import DataTable from '../components/Bi/DataTable.vue';

export default {
components:{DataTable},
setup(){
    const is_report = ref(false);

    return{
        is_report,
    }
}
}
</script>

<style scoped>
.bi-main{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
    "data-table      data-table     data-table";
    padding: 5px;
    grid-template-columns: 40% 40% 20%;
    grid-template-rows: 100%;
}
.counters{
    width: 100%;
    grid-area: counters;
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
}
.counter{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 180px;
    height: 100%;
    background: var(--secondary);
    margin-left: 5px;
    border-radius: 5px;
    padding: 5px;
}
.data-table{
    width: 100%;
    height: 100%;
    grid-area: data-table;
}
.data-options{
    width: 100%;
    height: 100%;
    grid-area: data-options;
}
</style>